.App {
  text-align: center;
  background-color: #ebf4f7;
}

body {
  background-color: #ebf4f7;
}

.App-logo {
  max-width: 280px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Header Component Style Start here */
.header {
  display: flex;
  background-color: #ebf4f7;
  min-height: 100px;
  padding : 0 5%;
}

.column-1 {
  width: 40%;
  margin:auto;
  padding:auto;
  text-align:start;
  
}
.column-2 {
  width: 60%;
  margin:auto;
  padding:auto;
  text-align:end;
  
}

/* Browser Size Component Style Start here */
.bs-heading {
  color:#000000;
  Font-size: 19px;
}

.bs-heading-3 {
  color:black;
  font-size: 22px;
}

.bs-heading-start{
  color:black;
  width:45%;
  display:inline-block;
  Font-size: 40px;
  margin-top:150px;
  font-weight:600;
  text-align: right;}

.bs-heading-end{
  color:black;
  width:45%;
  display:inline-block;
  Font-size: 40px;
  margin-top:130px;
  font-weight:600;
  text-align: left;
}

.bs-heading1-after {
  Font-size: 45px;
  width:10%;
  display:inline-block;
  margin-top:0px;
  font-weight:800;
}

.bs-heading-2 {
  font-size: 15px;
  font-weight:600;
  color:#3f3f3f;
  margin: 12px 0;
}

.browser-container {
  background-image: url(./images/Browsermock.png);
  background-size: cover;
  width:500px;
  min-height: 300px;
  display:inline-block;
}
 
.browser-size-container{
  text-align:center;
}

.browser-size-text {
  color: red;
}


/* Button Style Start Here */
.button-50 {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  overflow: visible;
  padding: 8px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button-50:hover {
  background-color: #6d6d6d;
  border: 1px solid #6d6d6d;
  border-radius:0;
}


@media (min-width: 768px) {
  .button-50 {
    padding: 8px 50px;
  }
}

/* Main Content CSS Stare Here */

.container-tool-wrapper {
  padding : 0 5%;
  display:flex;
}

.container-tool {
  float: left;
}

.main {
  width: 65%;
  min-height:300px;
}

.sidebar {
  width: 32%;
  margin-left:3%;
  min-height:300px;
}


.tool {
  flex-wrap: wrap;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255,255,255,var(--bg-opacity));
  border-width: 1px;
  --border-opacity: 1;
  border-color: #dfe3e8;
  border-color: rgba(223,227,232,var(--border-opacity));
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
  padding:50px;
}

.tool-2 {
  flex-wrap: wrap;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255,255,255,var(--bg-opacity));
  border-width: 1px;
  --border-opacity: 1;
  text-align: left;
  border-color: #dfe3e8;
  border-color: rgba(223,227,232,var(--border-opacity));
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
  padding:50px;
  min-height: 436px;
}


.footer {
    display: block;
    background-color: #ebf4f7;
    min-height: 50px;
    padding: 0 5%;
    text-align: center;
}

hr {
  margin: 25px 0;
}