@media screen and (max-width: 720px) {
    .App-logo {
      max-width: 180px;
      pointer-events: none;
    }
  
    .App {
      text-align: center;
      padding:0 10px;
      background-color: #ebf4f7;
    }
  
    /* Main Content CSS Stare Here */
  
  .container-tool-wrapper {
    padding : 0 1%;
    display:block;
  }
  
  .container-tool {
    float: left;
  }
  
  .main {
    width: 100%;
    min-height:300px;
  }
  
  .sidebar {
    width: 100%;
    margin:20px 0;
    min-height:300px;
  }
  
  
  .tool {
    flex-wrap: wrap;
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255,255,255,var(--bg-opacity));
    border-width: 1px;
    --border-opacity: 1;
    border-color: #dfe3e8;
    border-color: rgba(223,227,232,var(--border-opacity));
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
    padding: 10px;
  }
  
  .tool-2 {
    flex-wrap: wrap;
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255,255,255,var(--bg-opacity));
    border-width: 1px;
    --border-opacity: 1;
    border-color: #dfe3e8;
    border-color: rgba(223,227,232,var(--border-opacity));
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
    padding: 30px;
    min-height: auto;
  }
  
  /* Browser Size Component Style Start here */
  .bs-heading {
    color:black;
    Font-size: 20px;
  }
  
  .bs-heading-start{
    color:black;
    width:43%;
    display:inline-block;
    Font-size: 38px;
    margin-top:130px;
    font-weight:700;
    text-align: right;
    padding-right:2%;
  }
  
  .bs-heading-end{
    color:black;
    width:43%;
    display:inline-block;
    Font-size: 40px;
    margin-top:130px;
    font-weight:700;
    text-align: left;
    padding-left:2%;
  }
  
  .bs-heading1-after {
    Font-size: 45px;
    width:10%;
    display:inline-block;
    margin-top:0px;
    font-weight:800;
    text-align: center;
  }

  .bs-heading-2{
    font-size:14px;
  }
  
  .browser-container {
    background-image: url(./images/Browsermock.png);
    background-size: cover;
    width:100%;
    min-height:100%;
    display:inline-block;
  }
   
  .browser-size-container{
    text-align:center;
  }

  .header {
    padding:0 5px;
    min-height: 70px;
  }

  /* Button Style Start Here */
.button-50 {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  overflow: visible;
  padding: 6px 20px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button-50:hover {
  background-color: #6d6d6d;
  border: 1px solid #6d6d6d;
  border-radius:0;
}
    
  }